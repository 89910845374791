
import { defineComponent, ref } from 'vue'
import '@progress/kendo-ui/js/kendo.multiselect'
import { MultiSelect } from '@progress/kendo-dropdowns-vue-wrapper'
import '@progress/kendo-ui/js/kendo.dropdownlist'
import { DropDownList } from '@progress/kendo-dropdowns-vue-wrapper'
import FeildInputGroup from '@/components/Form/FeildInputGroup.vue'

export default defineComponent({
  components: {
    MultiSelect,
    DropDownList,
    FeildInputGroup
  },
  setup() {
    // 凍結欄位
    const lockedColumnsArray = ref([
      { field: 'IsOnline', title: 'IP 狀態' },
      { field: 'Ipv4', title: 'IPv4' },
      { field: 'Ipv6', title: 'IPv6' },
      { field: 'MAC', title: 'MAC' }
    ])
    const lockedColumnsValue = ref(['Ipv4', 'MAC'])

    // 顯示欄位
    const displayColumnsArray = ref([
      { field: 'IsOnline', title: 'IP 狀態' },
      { field: 'Ipv4', title: 'IPv4' },
      { field: 'Ipv6', title: 'IPv6' },
      { field: 'MAC', title: 'MAC' },
      { field: 'Status', title: '主機狀態' },
      { field: 'Duid', title: 'DUID' },
      { field: 'SwitchPortName', title: 'Port 名稱' },
      { field: 'LastOnlineTime', title: '上次上線時間' },
      { field: 'Site', title: '區域' },
      { field: 'LinkLocal', title: 'Link Local' }
    ])
    const displayColumnsValue = ref([
      'IsOnline',
      'Ipv4',
      'MAC',
      'Status',
      'IPv6',
      'LastOnlineTime',
      'LinkLocal',
      'Site'
    ])

    // IPv6 顯示
    const ipv6DisplayArray = ref([
      { name: '網路名稱', value: 0 },
      { name: '完整位址', value: 1 }
    ])
    const ipv6DisplayValue = ref({ name: '網路名稱', value: 0 })

    // 提交表單
    const submitForm = () => {
      console.log('lockedColumnsValue:', lockedColumnsValue.value)
      console.log('displayColumnsValue:', displayColumnsValue.value)
      console.log('ipv6DisplayValue:', ipv6DisplayValue.value)
      // FIXME: 做後續處理
    }
    return {
      lockedColumnsArray,
      lockedColumnsValue,
      displayColumnsArray,
      displayColumnsValue,
      ipv6DisplayArray,
      ipv6DisplayValue,
      submitForm
    }
  }
})
