
import { defineComponent } from 'vue'
import '@progress/kendo-ui/js/kendo.tooltip'
import { KendoTooltip } from '@progress/kendo-popups-vue-wrapper'

export default defineComponent({
  components: {
    KendoTooltip
  },
  props: {
    field: String,
    dataItem: Object,
    format: String,
    className: String,
    columnIndex: Number,
    columnsCount: Number,
    rowType: String,
    level: Number,
    expanded: Boolean,
    editor: String
  },
  setup() {
    // MAC 狀態顏色
    // 白、綠、藍 ( 注意！ only for dark mode )
    const StatusColor = ['#E4E6EC', '#63D5AB', '#4BB7D6']

    // 根據資料 StatusCode (0,1,2,3...) 取得顏色
    const getStatusColor = StatusCode => {
      return StatusColor[StatusCode]
    }

    return {
      getStatusColor
    }
  }
})
