
import { defineComponent, onMounted, onBeforeUnmount, ref, reactive } from 'vue'
import ControlWrapper from '@/components/Control/ControlWrapper.vue'
import ControlButton from '@/components/Control/ControlButton.vue'
import ControlDropdown from '@/components/Control/ControlDropdown.vue'
import FilterWrapper from '@/components/Filter/FilterWrapper.vue'
import FilterTextInput from '@/components/Filter/FilterTextInput.vue'
import FilterUnitCheckbox from '@/components/Filter/FilterUnitCheckbox.vue'
import useFilterCheckbox from '@/composable/useFilterCheckbox'
import FilterUnitDeletable from '@/components/Filter/FilterUnitDeletable.vue'
import FilterUnitAdd from '@/components/Filter/FilterUnitAdd.vue'
import useFilterUnitList from '@/composable/useFilterUnitList'
import GridWrapper from '@/components/Grid/GridWrapper.vue'
import { Grid } from '@progress/kendo-vue-grid'
import useGridInHost from '@/composable/useGridInHost'
import GridPageInfo from '@/components/Grid/GridPageInfo.vue'
import '@progress/kendo-ui/js/kendo.menu'
import { ContextMenu } from '@progress/kendo-layout-vue-wrapper'
import ContextMenuItem from '@/components/ContextMenu/ContextMenuItem.vue'
import '@progress/kendo-ui/js/kendo.dialog'
import { Dialog } from '@progress/kendo-vue-dialogs'
import useToggle from '@/composable/useToggle'
import DisplaySettingForm from '@/components/Form/HostPage/DisplaySettingForm.vue'
import EmailReportForm from '@/components/Form/CustomizeReportTree/EmailReportForm.vue'
import { DropDownList } from '@progress/kendo-vue-dropdowns'
import { DateTimePicker } from '@progress/kendo-vue-dateinputs'
import BaseCheckbox from '@/components/Checkbox/BaseCheckbox.vue'
import useECartsInCustomizeReport from '@/composable/useECartsInCustomizeReport'
import elementResizeDetectorMaker from 'element-resize-detector'

import {
  eventCheckboxOptions,
  eventInitialCheckedIds,
  keyWithOptions,
  initialFilterUnitList,
  initialReportList,
  initialLegendList,
  gridMenuData
} from '@/mock/data/customizeReportTree'

export default defineComponent({
  components: {
    ControlWrapper,
    ControlButton,
    ControlDropdown,
    FilterWrapper,
    FilterTextInput,
    FilterUnitCheckbox,
    FilterUnitDeletable,
    FilterUnitAdd,
    GridWrapper,
    Grid,
    GridPageInfo,
    ContextMenu,
    ContextMenuItem,
    Dialog,
    DisplaySettingForm,
    EmailReportForm,
    DropDownList,
    DateTimePicker,
    BaseCheckbox
  },
  setup() {
    const reportTitle = ref('新 IPv4 日報表')
    const isTreeViewOpen = ref(true)

    // 所有報表選項
    // type: 報表類型，如自訂義、MAC、主機
    // isActive: 是否被選取
    // isTitle 是否為標題
    const reportList = ref(initialReportList)
    const toggleReportLink = id => {
      reportList.value = reportList.value.map(item => {
        if (item.id === id) {
          item.isActive = true
          reportTitle.value = item.text
        } else {
          item.isActive = false
        }
        return item
      })
    }

    // DateTimePicker defaultValue
    const defaultValue = ref(new Date())
    const defaultItem = {
      text: '時間'
    }
    const dates = ref([
      { text: '今天', id: 1 },
      { text: '當週', id: 2 },
      { text: '當月', id: 3 },
      { text: '當季', id: 4 },
      { text: '今年', id: 5 },
      { text: '昨天', id: 6 },
      { text: '上週', id: 7 },
      { text: '上月', id: 8 },
      { text: '上季', id: 9 }
    ])

    // 篩選相關
    // 文字輸入
    const textInput = ref('')
    // 事件類型 checkbox 篩選
    const {
      allChecked: eventAllChecked,
      checkedIds: eventCheckIds,
      onCheck: eventOnCheck,
      checkAll: eventCheckAll
    } = useFilterCheckbox(eventCheckboxOptions, eventInitialCheckedIds)

    // 自訂篩選相關
    const {
      filterUnitList,
      updateFilterUnit,
      deleteFilterUnit,
      addFilterUnit
    } = useFilterUnitList(initialFilterUnitList)

    // 提交篩選
    const submitFilter = () => {
      // FIXME: 這裡提供了文字輸入、勾選、自訂篩選組的值，可以做後續組裡
      console.log('textInput: ' + textInput.value)
      console.log('eventCheckIds: ' + eventCheckIds.value)
      console.log('filterUnitList: ' + filterUnitList.value)
    }

    // Grid 相關
    const {
      columns,
      dataItems,
      pageable,
      skip,
      take,
      result,
      total,
      sort,
      createRandomData,
      pageChangeHandler,
      onRowClick,
      columnReorder,
      sortChangeHandler
    } = useGridInHost()

    onMounted(() => {
      dataItems.value = createRandomData(255) as never[]
    })

    // cell default template 取得此欄位 fieldName 對應的值
    const getNestedValue = (fieldName, dataItem) => {
      const path = fieldName.split('.')
      let data = dataItem
      path.forEach(p => {
        data = data ? data[p] : undefined
      })

      return data
    }

    // ContextMenu 相關 (In Kendo Grid)
    const onGridMenuOpen = e => {
      // Fires before a sub-menu or the ContextMenu is opened
      // CHECK: (https://www.telerik.com/kendo-vue-ui/components/layout/api/contextmenu/ContextMenu/#toc-open)
      // 取得此欄位 td 的 ['data-id']
      const Id = e.target.getAttribute('data-id')
      console.log('Id: ' + Id)
      // 觸發欄位 select
      e.target.click()
    }

    // Dialog 相關
    // 顯示設定
    const {
      show: showDisplaySettings,
      toggle: toggleDisplaySettings
    } = useToggle()
    // Email 寄送報表
    const { show: showEmailReport, toggle: toggleEmailReport } = useToggle()

    // 事件圖相關
    const legendList = reactive(initialLegendList)
    let chartInstanse
    const { chartElement, draw } = useECartsInCustomizeReport(legendList)

    // 切換折線圖
    const toggleSelect = legendName => {
      console.log('===', legendName, 'legendToggleSelect ===')
      if (chartInstanse) {
        // @ts-ignore
        chartInstanse.dispatchAction({
          type: 'legendToggleSelect',
          name: legendName
        })
      }
    }

    // Resize 相關
    const erd = elementResizeDetectorMaker()
    const resizeListener = () => chartInstanse.resize()

    onMounted(() => {
      // 開始繪圖
      chartInstanse = draw()
      // onMount 3000ms 後，開始監聽 chartElement 寬度變化
      setTimeout(() => {
        erd.listenTo(chartElement.value, resizeListener)
      }, 3000)
    })

    onBeforeUnmount(() => {
      // 移除監聽器
      erd.removeListener(chartElement.value, resizeListener)
    })

    return {
      // TreeView 相關
      isTreeViewOpen,
      // 報表選項
      reportTitle,
      reportList,
      toggleReportLink,
      // Date
      defaultValue,
      defaultItem,
      dates,
      // 篩選相關：文字輸入
      textInput,
      submitFilter,
      // 篩選相關：事件類型 多選選項
      eventCheckboxOptions,
      eventCheckIds,
      eventAllChecked,
      eventOnCheck,
      eventCheckAll,
      // 篩選相關：自訂篩選
      keyWithOptions,
      filterUnitList,
      updateFilterUnit,
      deleteFilterUnit,
      addFilterUnit,
      // Grid 相關
      columns,
      dataItems,
      pageable,
      skip,
      take,
      result,
      total,
      sort,
      createRandomData,
      pageChangeHandler,
      onRowClick,
      columnReorder,
      sortChangeHandler,
      getNestedValue,
      gridMenuData,
      onGridMenuOpen,
      // Dialog 相關：顯示設定
      showDisplaySettings,
      toggleDisplaySettings,
      // Dialog 相關：Email 寄送報表
      showEmailReport,
      toggleEmailReport,
      // 事件圖表相關
      legendList,
      toggleSelect,
      chartElement
    }
  }
})
