<template>
  <div
    id="page-customize-report-tree"
    class="main-list-view"
    :class="{ 'is-treeview-open': isTreeViewOpen }"
  >
    <!-- 左側：樹狀圖 -->
    <div class="tree-nav-container">
      <h3 class="tree-title">報表</h3>
      <div class="treeview-wrapper">
        <FilterTextInput
          :placeholder="'查詢報表名稱'"
          v-model.trim="textInput"
          @submitFilter="submitFilter"
        />
        <GridPageInfo :skip="0" :take="50" :total="50" />
        <div class="report-list-wrapper">
          <div v-for="reportItem in reportList" :key="reportItem.id">
            <!-- 報表標題 -->
            <div v-if="reportItem.isTitle" class="report-title">
              {{ reportItem.text }}
            </div>
            <!-- 報表連結 -->
            <div
              v-else
              class="report-link"
              :class="{ 'is-active': reportItem.isActive }"
              @click="toggleReportLink(reportItem.id)"
            >
              <SvgIcon :icon="reportItem.type == 'self' ? 'star' : 'mac'" />
              {{ reportItem.text }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 右側：列表容器 -->
    <div class="main-list-container">
      <div class="title-wrapper">
        <h2 class="title">{{ reportTitle }}</h2>
        <div
          class="toggle-treeview-btn"
          @click="isTreeViewOpen = !isTreeViewOpen"
        >
          <SvgIcon :icon="isTreeViewOpen ? 'expand' : 'search'" />
        </div>
      </div>
      <!-- 上：控制列容器 -->
      <ControlWrapper>
        <ControlButton
          iconName="setup"
          text="顯示設定"
          @click="toggleDisplaySettings"
        />
        <ControlDropdown
          iconName="download"
          text="匯出"
          id="export"
          :menuData="[{ name: '匯出 Excel' }, { name: '匯出 CSV' }]"
        />
        <ControlButton iconName="plus" text="新增自定義報表" />
        <div class="vertical-divider"></div>
        <ControlButton
          iconName="email"
          text="Email 寄送報表"
          @click="toggleEmailReport"
        />
      </ControlWrapper>
      <!-- 中：篩選列容器 -->
      <FilterWrapper>
        <DropDownList
          :default-item="{ text: '折線圖' }"
          :data-items="[{ text: '折線圖', id: 1 }]"
          :text-field="'text'"
          :style="{ width: '150px', marginRight: '10px' }"
        />
        <DropDownList
          :default-item="{ text: '合併事件類型' }"
          :data-items="[{ text: '合併事件類型', id: 1 }]"
          :text-field="'text'"
          :style="{ width: '150px', marginRight: '10px' }"
        />
        <DropDownList
          :default-item="defaultItem"
          :data-items="dates"
          :text-field="'text'"
          class="time-dropdown"
        />
        <DateTimePicker
          :default-value="defaultValue"
          :format="'yyyy/MM/dd hh:mm:ss a'"
          :width="'280px'"
          :style="{ marginLeft: '10px', marginRight: '10px' }"
        >
        </DateTimePicker>
        <DateTimePicker
          :default-value="defaultValue"
          :format="'yyyy/MM/dd hh:mm:ss a'"
          :width="'280px'"
          :style="{ marginRight: '10px' }"
        >
        </DateTimePicker>
        <FilterUnitCheckbox
          :allChecked="eventAllChecked"
          :checkedIds="eventCheckIds"
          :keyName="'事件類型'"
        >
          <label class="base-checkbox filter-checkbox">
            <input
              type="checkbox"
              v-model="eventAllChecked"
              @change="eventCheckAll"
            />
            <div class="custom-checkbox"></div>
            <span>全部</span>
          </label>
          <div class="divider"></div>
          <template v-for="option in eventCheckboxOptions" :key="option.id">
            <label class="base-checkbox filter-checkbox">
              <input
                type="checkbox"
                :value="option.id"
                v-model="eventCheckIds"
                @change="eventOnCheck"
              />
              <div class="custom-checkbox"></div>
              <span>{{ option.name }}</span>
            </label>
            <div class="divider" v-if="option.divider"></div>
          </template>
        </FilterUnitCheckbox>
        <FilterUnitDeletable
          v-for="filterUnit in filterUnitList"
          :filterUnit="filterUnit"
          :key="filterUnit.key"
          @updateFilterUnit="updateFilterUnit"
          @deleteFilterUnit="deleteFilterUnit"
        />
        <FilterUnitAdd
          :keyWithOptions="keyWithOptions"
          @addFilterUnit="addFilterUnit"
        />
        <div class="spacer"></div>
      </FilterWrapper>
      <GridPageInfo :skip="skip" :take="take" :total="total" />
      <!-- 中：事件圖容器 -->
      <div class="events-chart-wrapper">
        <div class="events-chart" ref="chartElement"></div>
        <div class="legend-list">
          <BaseCheckbox
            class="legend-list-item"
            v-model="legend.checked"
            v-for="legend in legendList"
            :key="legend.id"
            @change="toggleSelect(legend.name)"
          >
            <div class="legend-name">{{ legend.name }}</div>
            <div
              class="legend-tag"
              :style="{ backgroundColor: legend.color }"
            ></div>
          </BaseCheckbox>
        </div>
      </div>
      <!-- 下：表格容器 -->
      <GridWrapper>
        <Grid
          id="kendo-grid"
          :style="{ height: 'calc(100vh - 530px)' }"
          :columns="columns"
          :data-items="result"
          :cell-render="'defaultTemplate'"
          :pageable="pageable"
          :skip="skip"
          :take="take"
          :total="total"
          @pagechange="pageChangeHandler"
          :scrollable="'true'"
          :selected-field="'selected'"
          @rowclick="onRowClick"
          :reorderable="true"
          @columnreorder="columnReorder"
          :sortable="true"
          :sort="sort"
          @sortchange="sortChangeHandler"
          :class="{ 'is-sortable': true }"
        >
          <!-- 預設 cell render template，帶有 ['data-id'] -->
          <template v-slot:defaultTemplate="{ props }">
            <td :class="props.className" :data-id="props.dataItem.Id">
              {{ getNestedValue(props.field, props.dataItem) }}
            </td>
          </template>
        </Grid>
        <ContextMenu
          :target="'#kendo-grid'"
          :filter="'td'"
          @open="onGridMenuOpen"
        >
          <ContextMenuItem
            v-for="(tree, index) in gridMenuData"
            :key="index"
            :tree="tree"
          />
        </ContextMenu>
      </GridWrapper>
    </div>
    <!-- 隱藏：Dialog 容器 -->
    <div class="dialog-container">
      <Dialog
        v-if="showDisplaySettings"
        :title="'顯示設定'"
        :width="930"
        :height="536"
        @close="toggleDisplaySettings"
      >
        <DisplaySettingForm @close="toggleDisplaySettings" />
      </Dialog>
      <Dialog
        v-if="showEmailReport"
        :title="'Email 寄送報表'"
        :width="930"
        :height="560"
        @close="toggleEmailReport"
      >
        <EmailReportForm @close="toggleEmailReport" />
      </Dialog>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, onBeforeUnmount, ref, reactive } from 'vue'
import ControlWrapper from '@/components/Control/ControlWrapper.vue'
import ControlButton from '@/components/Control/ControlButton.vue'
import ControlDropdown from '@/components/Control/ControlDropdown.vue'
import FilterWrapper from '@/components/Filter/FilterWrapper.vue'
import FilterTextInput from '@/components/Filter/FilterTextInput.vue'
import FilterUnitCheckbox from '@/components/Filter/FilterUnitCheckbox.vue'
import useFilterCheckbox from '@/composable/useFilterCheckbox'
import FilterUnitDeletable from '@/components/Filter/FilterUnitDeletable.vue'
import FilterUnitAdd from '@/components/Filter/FilterUnitAdd.vue'
import useFilterUnitList from '@/composable/useFilterUnitList'
import GridWrapper from '@/components/Grid/GridWrapper.vue'
import { Grid } from '@progress/kendo-vue-grid'
import useGridInHost from '@/composable/useGridInHost'
import GridPageInfo from '@/components/Grid/GridPageInfo.vue'
import '@progress/kendo-ui/js/kendo.menu'
import { ContextMenu } from '@progress/kendo-layout-vue-wrapper'
import ContextMenuItem from '@/components/ContextMenu/ContextMenuItem.vue'
import '@progress/kendo-ui/js/kendo.dialog'
import { Dialog } from '@progress/kendo-vue-dialogs'
import useToggle from '@/composable/useToggle'
import DisplaySettingForm from '@/components/Form/HostPage/DisplaySettingForm.vue'
import EmailReportForm from '@/components/Form/CustomizeReportTree/EmailReportForm.vue'
import { DropDownList } from '@progress/kendo-vue-dropdowns'
import { DateTimePicker } from '@progress/kendo-vue-dateinputs'
import BaseCheckbox from '@/components/Checkbox/BaseCheckbox.vue'
import useECartsInCustomizeReport from '@/composable/useECartsInCustomizeReport'
import elementResizeDetectorMaker from 'element-resize-detector'

import {
  eventCheckboxOptions,
  eventInitialCheckedIds,
  keyWithOptions,
  initialFilterUnitList,
  initialReportList,
  initialLegendList,
  gridMenuData
} from '@/mock/data/customizeReportTree'

export default defineComponent({
  components: {
    ControlWrapper,
    ControlButton,
    ControlDropdown,
    FilterWrapper,
    FilterTextInput,
    FilterUnitCheckbox,
    FilterUnitDeletable,
    FilterUnitAdd,
    GridWrapper,
    Grid,
    GridPageInfo,
    ContextMenu,
    ContextMenuItem,
    Dialog,
    DisplaySettingForm,
    EmailReportForm,
    DropDownList,
    DateTimePicker,
    BaseCheckbox
  },
  setup() {
    const reportTitle = ref('新 IPv4 日報表')
    const isTreeViewOpen = ref(true)

    // 所有報表選項
    // type: 報表類型，如自訂義、MAC、主機
    // isActive: 是否被選取
    // isTitle 是否為標題
    const reportList = ref(initialReportList)
    const toggleReportLink = id => {
      reportList.value = reportList.value.map(item => {
        if (item.id === id) {
          item.isActive = true
          reportTitle.value = item.text
        } else {
          item.isActive = false
        }
        return item
      })
    }

    // DateTimePicker defaultValue
    const defaultValue = ref(new Date())
    const defaultItem = {
      text: '時間'
    }
    const dates = ref([
      { text: '今天', id: 1 },
      { text: '當週', id: 2 },
      { text: '當月', id: 3 },
      { text: '當季', id: 4 },
      { text: '今年', id: 5 },
      { text: '昨天', id: 6 },
      { text: '上週', id: 7 },
      { text: '上月', id: 8 },
      { text: '上季', id: 9 }
    ])

    // 篩選相關
    // 文字輸入
    const textInput = ref('')
    // 事件類型 checkbox 篩選
    const {
      allChecked: eventAllChecked,
      checkedIds: eventCheckIds,
      onCheck: eventOnCheck,
      checkAll: eventCheckAll
    } = useFilterCheckbox(eventCheckboxOptions, eventInitialCheckedIds)

    // 自訂篩選相關
    const {
      filterUnitList,
      updateFilterUnit,
      deleteFilterUnit,
      addFilterUnit
    } = useFilterUnitList(initialFilterUnitList)

    // 提交篩選
    const submitFilter = () => {
      // FIXME: 這裡提供了文字輸入、勾選、自訂篩選組的值，可以做後續組裡
      console.log('textInput: ' + textInput.value)
      console.log('eventCheckIds: ' + eventCheckIds.value)
      console.log('filterUnitList: ' + filterUnitList.value)
    }

    // Grid 相關
    const {
      columns,
      dataItems,
      pageable,
      skip,
      take,
      result,
      total,
      sort,
      createRandomData,
      pageChangeHandler,
      onRowClick,
      columnReorder,
      sortChangeHandler
    } = useGridInHost()

    onMounted(() => {
      dataItems.value = createRandomData(255) as never[]
    })

    // cell default template 取得此欄位 fieldName 對應的值
    const getNestedValue = (fieldName, dataItem) => {
      const path = fieldName.split('.')
      let data = dataItem
      path.forEach(p => {
        data = data ? data[p] : undefined
      })

      return data
    }

    // ContextMenu 相關 (In Kendo Grid)
    const onGridMenuOpen = e => {
      // Fires before a sub-menu or the ContextMenu is opened
      // CHECK: (https://www.telerik.com/kendo-vue-ui/components/layout/api/contextmenu/ContextMenu/#toc-open)
      // 取得此欄位 td 的 ['data-id']
      const Id = e.target.getAttribute('data-id')
      console.log('Id: ' + Id)
      // 觸發欄位 select
      e.target.click()
    }

    // Dialog 相關
    // 顯示設定
    const {
      show: showDisplaySettings,
      toggle: toggleDisplaySettings
    } = useToggle()
    // Email 寄送報表
    const { show: showEmailReport, toggle: toggleEmailReport } = useToggle()

    // 事件圖相關
    const legendList = reactive(initialLegendList)
    let chartInstanse
    const { chartElement, draw } = useECartsInCustomizeReport(legendList)

    // 切換折線圖
    const toggleSelect = legendName => {
      console.log('===', legendName, 'legendToggleSelect ===')
      if (chartInstanse) {
        // @ts-ignore
        chartInstanse.dispatchAction({
          type: 'legendToggleSelect',
          name: legendName
        })
      }
    }

    // Resize 相關
    const erd = elementResizeDetectorMaker()
    const resizeListener = () => chartInstanse.resize()

    onMounted(() => {
      // 開始繪圖
      chartInstanse = draw()
      // onMount 3000ms 後，開始監聽 chartElement 寬度變化
      setTimeout(() => {
        erd.listenTo(chartElement.value, resizeListener)
      }, 3000)
    })

    onBeforeUnmount(() => {
      // 移除監聽器
      erd.removeListener(chartElement.value, resizeListener)
    })

    return {
      // TreeView 相關
      isTreeViewOpen,
      // 報表選項
      reportTitle,
      reportList,
      toggleReportLink,
      // Date
      defaultValue,
      defaultItem,
      dates,
      // 篩選相關：文字輸入
      textInput,
      submitFilter,
      // 篩選相關：事件類型 多選選項
      eventCheckboxOptions,
      eventCheckIds,
      eventAllChecked,
      eventOnCheck,
      eventCheckAll,
      // 篩選相關：自訂篩選
      keyWithOptions,
      filterUnitList,
      updateFilterUnit,
      deleteFilterUnit,
      addFilterUnit,
      // Grid 相關
      columns,
      dataItems,
      pageable,
      skip,
      take,
      result,
      total,
      sort,
      createRandomData,
      pageChangeHandler,
      onRowClick,
      columnReorder,
      sortChangeHandler,
      getNestedValue,
      gridMenuData,
      onGridMenuOpen,
      // Dialog 相關：顯示設定
      showDisplaySettings,
      toggleDisplaySettings,
      // Dialog 相關：Email 寄送報表
      showEmailReport,
      toggleEmailReport,
      // 事件圖表相關
      legendList,
      toggleSelect,
      chartElement
    }
  }
})
</script>

<style scoped></style>
