<template>
  <div class="form-wrapper">
    <form>
      <div class="single-feild-group">
        <label class="feild-name">檔案類型</label>
        <FeildInputGroup :isInvalid="false" :errorMsg="'輸入錯誤'">
          <DropDownList
            style="width: 400px;"
            v-model="fileTypeValue"
            :data-source="fileTypeArray"
            :data-text-field="'name'"
            :data-value-field="'value'"
          />
        </FeildInputGroup>
      </div>
      <div class="single-feild-group">
        <label class="feild-name">主旨</label>
        <FeildInputGroup :isInvalid="false" :errorMsg="'輸入錯誤'">
          <KendoInput
            :type="'text'"
            style="width: 400px;"
            v-model="subject"
            :required="true"
          />
        </FeildInputGroup>
      </div>
      <div class="single-feild-group">
        <label class="feild-name">收件者</label>
        <FeildInputGroup :isInvalid="false" :errorMsg="'輸入錯誤'">
          <MultiSelect
            style="width: 400px;"
            v-model="receiverValue"
            :data-source="receiverArray"
            :data-text-field="'title'"
            :data-value-field="'field'"
            :placeholder="' - 請選擇 - '"
          />
        </FeildInputGroup>
      </div>
      <div class="single-feild-group">
        <label class="feild-name">排程</label>
        <div>
          <div class="mb-2">
            <BaseRadio
              class="form-radio"
              name="schedule"
              :value="'single'"
              v-model="schedule"
            >
              單次寄送
            </BaseRadio>
          </div>
          <div class="mb-2 d-flex align-items-center">
            <BaseRadio
              class="form-radio"
              name="schedule"
              :value="'scheduled'"
              v-model="schedule"
            >
              於
            </BaseRadio>
            <DropDownList
              style="width: 120px; margin-left: 14px;"
              :data-source="[
                { name: '每天', value: 'day' },
                { name: '每週', value: 'week' }
              ]"
              :data-text-field="'name'"
              :data-value-field="'value'"
            />
            <TimePicker :style="{ height: '35px' }" :value="new Date()" />
            <span class="ml-3 text-in-form">寄送</span>
            <a href="#" class="ml-3 link-in-form">查看所有訂閱報表</a>
          </div>
        </div>
      </div>
      <div class="footer-group">
        <button
          type="submit"
          class="btn btn-primary form-btn mr-2"
          @click.prevent="submitForm"
        >
          完成設定
        </button>
        <button class="btn form-btn-outline" @click.prevent="$emit('close')">
          取消
        </button>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import '@progress/kendo-ui/js/kendo.multiselect'
import { MultiSelect } from '@progress/kendo-dropdowns-vue-wrapper'
import '@progress/kendo-ui/js/kendo.dropdownlist'
import { DropDownList } from '@progress/kendo-dropdowns-vue-wrapper'
import FeildInputGroup from '@/components/Form/FeildInputGroup.vue'
import { Input } from '@progress/kendo-vue-inputs'
import BaseRadio from '@/components/Radio/BaseRadio.vue'
import '@progress/kendo-ui/js/kendo.timepicker'
import { TimePicker } from '@progress/kendo-dateinputs-vue-wrapper'

export default defineComponent({
  components: {
    MultiSelect,
    DropDownList,
    FeildInputGroup,
    KendoInput: Input,
    BaseRadio,
    TimePicker
  },
  setup() {
    // 檔案類型
    const fileTypeArray = ref([
      { name: 'Excel', value: 0 },
      { name: 'CSV', value: 1 }
    ])
    const fileTypeValue = ref({ name: 'Excel', value: 0 })

    // 主旨
    const subject = ref('報表')

    // 收件人
    const receiverArray = ref([
      {
        field: 'Kevin@polish-design.com.tw',
        title: 'Kevin@polish-design.com.tw'
      }
    ])
    const receiverValue = ref(['Kevin@polish-design.com.tw'])

    // 排程
    const schedule = ref('scheduled')

    // 提交表單
    const submitForm = () => {
      console.log('fileTypeValue:', fileTypeValue.value)
      console.log('receiverValue:', receiverValue.value)
      // FIXME: 做後續處理
    }
    return {
      fileTypeArray,
      fileTypeValue,
      subject,
      receiverArray,
      receiverValue,
      submitForm,
      schedule
    }
  }
})
</script>

<style scoped></style>
