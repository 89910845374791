
import { defineComponent } from 'vue'
import '@progress/kendo-ui/js/kendo.tooltip'
import { KendoTooltip } from '@progress/kendo-popups-vue-wrapper'

export default defineComponent({
  components: {
    KendoTooltip
  },
  props: {
    field: String,
    dataItem: Object,
    format: String,
    className: String,
    columnIndex: Number,
    columnsCount: Number,
    rowType: String,
    level: Number,
    expanded: Boolean,
    editor: String
  },
  setup() {
    const IconName = {
      Mac: 'os-mac',
      Windows: 'os-windows',
      Pixis: 'os-pixis'
    }
    const getIconName = OSDetail => {
      if (Object.prototype.hasOwnProperty.call(IconName, OSDetail)) {
        return IconName[OSDetail]
      }
      return 'os-unknown'
    }
    return {
      getIconName
    }
  }
})
