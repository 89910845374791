// 報表 列表

// 所有事件類型 checkbox 選項
const eventCheckboxOptions = [
  { id: 'SwitchTimeout', name: '未回應', divider: false }
]
// 起始的事件類型態勾選 checkbox 選項
const eventInitialCheckedIds = ['SwitchTimeout']

// 可用的篩選條件：FilterUnitAdd.vue 元件
const keyWithOptions = [
  { key: '區域名稱', options: ['Taipei Office', 'Kaohsiung Office'] }
]
// 起始的篩選組合：FilterUnitDeletable.vue 元件
const initialFilterUnitList = []

// 起始報表選項
const initialReportList = [
  {
    id: 0,
    text: '自定義報表',
    type: 'self',
    isActive: false,
    isTitle: true
  },
  {
    id: 1,
    text: '新 IPv4 日報表',
    type: 'self',
    isActive: true,
    isTitle: false
  },
  {
    id: 2,
    text: '閒置 Switch Port 報表',
    type: 'self',
    isActive: false,
    isTitle: false
  },
  {
    id: 3,
    text: '違反固定 IP 封鎖週報表',
    type: 'self',
    isActive: false,
    isTitle: false
  },
  {
    id: 4,
    text: 'MAC 報表',
    type: 'mac',
    isActive: false,
    isTitle: true
  },
  {
    id: 5,
    text: '未授權 MAC 報表',
    type: 'mac',
    isActive: false,
    isTitle: false
  },
  {
    id: 6,
    text: '新 MAC 報表',
    type: 'mac',
    isActive: false,
    isTitle: false
  },
  {
    id: 7,
    text: '主機報表',
    type: 'host',
    isActive: false,
    isTitle: true
  },
  {
    id: 8,
    text: '未關機報表',
    type: 'host',
    isActive: false,
    isTitle: false
  },
  {
    id: 9,
    text: '未使用 IPv6 主機報表',
    type: 'host',
    isActive: false,
    isTitle: false
  },
  {
    id: 10,
    text: '只使用 IPv6 主機報表',
    type: 'host',
    isActive: false,
    isTitle: false
  },
  {
    id: 11,
    text: 'GPO 派送報表',
    type: 'host',
    isActive: false,
    isTitle: false
  },
  {
    id: 12,
    text: 'AD 登入報表表',
    type: 'host',
    isActive: false,
    isTitle: false
  },
  {
    id: 13,
    text: 'WSUS 報表',
    type: 'host',
    isActive: false,
    isTitle: false
  },
  {
    id: 14,
    text: '本機管理員帳號報表',
    type: 'host',
    isActive: false,
    isTitle: false
  },
  {
    id: 15,
    text: '違反 Dual Stack 規則報表',
    type: 'host',
    isActive: false,
    isTitle: false
  }
]

// ContextMenu 選項 (In Kendo Grid)
const gridMenuData = [
  {
    name: 'IP 政策設定',
    node: [
      { name: '取消 IP 封鎖' },
      { name: '設定固定 IP' },
      { name: '取消重要 IP' }
    ]
  },
  {
    name: 'MAC 政策設定'
  },
  {
    name: 'IP + MAC 政策'
  },
  {
    name: '符規檢查'
  },
  {
    name: '白名單 + MAC 政策'
  },
  { name: '設定 GLBP' },
  { name: '取消 GLBP' },
  { name: '詳細資訊' }
]

// 起始事件圖表 LegentList
const initialLegendList = [
  {
    id: 0,
    name: '系統事件',
    checked: true,
    color: '#355DFF'
  },
  {
    id: 1,
    name: '管理日誌',
    checked: true,
    color: '#3DCB97'
  },
  {
    id: 2,
    name: '異常事件',
    checked: true,
    color: '#FCCB36'
  },
  {
    id: 3,
    name: '主機事件',
    checked: false,
    color: '#B7C0EB'
  },
  {
    id: 4,
    name: '封鎖事件',
    checked: true,
    color: '#E85153'
  },
  {
    id: 5,
    name: '授權事件',
    checked: false,
    color: '#6C5DD3'
  }
]

export {
  eventCheckboxOptions,
  eventInitialCheckedIds,
  keyWithOptions,
  initialFilterUnitList,
  initialReportList,
  initialLegendList,
  gridMenuData
}
