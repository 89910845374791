<template>
  <td class="status-cell" :data-id="dataItem.Id">
    <template v-if="dataItem.Status.length > 0">
      <template v-for="(item, idx) in dataItem.Status" :key="idx">
        <KendoTooltip
          id="tooltip"
          :title="getTitle(item.Content)"
          :position="'top'"
        >
          <SvgIconFixedColor :icon="getIconName(item.IconCode)" />
        </KendoTooltip>
      </template>
    </template>
  </td>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import '@progress/kendo-ui/js/kendo.tooltip'
import { KendoTooltip } from '@progress/kendo-popups-vue-wrapper'

export default defineComponent({
  components: {
    KendoTooltip
  },
  props: {
    field: String,
    dataItem: Object,
    format: String,
    className: String,
    columnIndex: Number,
    columnsCount: Number,
    rowType: String,
    level: Number,
    expanded: Boolean,
    editor: String
  },
  setup() {
    // 根據 IconCode 取得 Icon 名稱
    // FIXME: 尚未包含全部 Icons
    /*
     * 0: 禁止變更 IP
     * 1: MAC 封鎖
     * 2: 重要 IP
     * 3: IP 封鎖
     * 4: 未授權 MAC 封鎖
     * 5: 未符規白名單設備
     * ...
     */
    const getIconName = IconCode => `host-status-${IconCode}`

    // 根據 Content 取得 tooltip title
    // 這裡 Content 字串可以有換行符，例如：'IP 封鎖(管理者)<br>172.168.2.3'
    const getTitle = Content =>
      `<div style='text-align: center;'>${Content}</div>`
    return {
      getIconName,
      getTitle
    }
  }
})
</script>

<style scoped lang="scss">
.status-cell {
  text-align: right;
}
</style>
