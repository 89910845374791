
import { defineComponent, ref } from 'vue'
import '@progress/kendo-ui/js/kendo.multiselect'
import { MultiSelect } from '@progress/kendo-dropdowns-vue-wrapper'
import '@progress/kendo-ui/js/kendo.dropdownlist'
import { DropDownList } from '@progress/kendo-dropdowns-vue-wrapper'
import FeildInputGroup from '@/components/Form/FeildInputGroup.vue'
import { Input } from '@progress/kendo-vue-inputs'
import BaseRadio from '@/components/Radio/BaseRadio.vue'
import '@progress/kendo-ui/js/kendo.timepicker'
import { TimePicker } from '@progress/kendo-dateinputs-vue-wrapper'

export default defineComponent({
  components: {
    MultiSelect,
    DropDownList,
    FeildInputGroup,
    KendoInput: Input,
    BaseRadio,
    TimePicker
  },
  setup() {
    // 檔案類型
    const fileTypeArray = ref([
      { name: 'Excel', value: 0 },
      { name: 'CSV', value: 1 }
    ])
    const fileTypeValue = ref({ name: 'Excel', value: 0 })

    // 主旨
    const subject = ref('報表')

    // 收件人
    const receiverArray = ref([
      {
        field: 'Kevin@polish-design.com.tw',
        title: 'Kevin@polish-design.com.tw'
      }
    ])
    const receiverValue = ref(['Kevin@polish-design.com.tw'])

    // 排程
    const schedule = ref('scheduled')

    // 提交表單
    const submitForm = () => {
      console.log('fileTypeValue:', fileTypeValue.value)
      console.log('receiverValue:', receiverValue.value)
      // FIXME: 做後續處理
    }
    return {
      fileTypeArray,
      fileTypeValue,
      subject,
      receiverArray,
      receiverValue,
      submitForm,
      schedule
    }
  }
})
