<template>
  <td
    class="is-online-cell"
    :class="{ offline: dataItem.IsOnline === 'Offline' }"
    :data-id="dataItem.Id"
  >
    <span class="status-dot"></span>
    {{ dataItem.IsOnline }}
  </td>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    field: String,
    dataItem: Object,
    format: String,
    className: String,
    columnIndex: Number,
    columnsCount: Number,
    rowType: String,
    level: Number,
    expanded: Boolean,
    editor: String
  },
  setup() {
    return {}
  }
})
</script>

<style scoped></style>
