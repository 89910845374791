
import { defineComponent, computed } from 'vue'

export default defineComponent({
  props: {
    modelValue: String,
    value: String,
    name: String
  },
  emits: ['update:modelValue'],
  setup(props, ctx) {
    // CHECK: (https://www.smashingmagazine.com/2017/08/creating-custom-inputs-vue-js/)

    // 計算是否 checked
    const shouldBeChecked = computed(() => {
      return props.modelValue == props.value
    })
    // 更新父層 v-model value
    const updateInput = () => {
      ctx.emit('update:modelValue', props.value)
    }
    return {
      shouldBeChecked,
      updateInput
    }
  }
})
