<template>
  <td class="os-detail-cell" :data-id="dataItem.Id">
    <KendoTooltip id="tooltip" :title="dataItem.OSDetail" :position="'top'">
      <SvgIconFixedColor
        :icon="getIconName(dataItem.OSDetail)"
        class="os-icon"
      />
    </KendoTooltip>
  </td>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import '@progress/kendo-ui/js/kendo.tooltip'
import { KendoTooltip } from '@progress/kendo-popups-vue-wrapper'

export default defineComponent({
  components: {
    KendoTooltip
  },
  props: {
    field: String,
    dataItem: Object,
    format: String,
    className: String,
    columnIndex: Number,
    columnsCount: Number,
    rowType: String,
    level: Number,
    expanded: Boolean,
    editor: String
  },
  setup() {
    const IconName = {
      Mac: 'os-mac',
      Windows: 'os-windows',
      Pixis: 'os-pixis'
    }
    const getIconName = OSDetail => {
      if (Object.prototype.hasOwnProperty.call(IconName, OSDetail)) {
        return IconName[OSDetail]
      }
      return 'os-unknown'
    }
    return {
      getIconName
    }
  }
})
</script>

<style scoped lang="scss">
.os-detail-cell {
  text-align: center;
  padding-top: 14px;
  padding-bottom: 14px;
  font-size: 0;

  .os-icon {
    width: 24px;
    height: 24px;
    margin: 0;
  }
}
</style>
