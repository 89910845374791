import { ref, computed, markRaw } from 'vue'
import { orderBy } from '@progress/kendo-data-query'
// Cells
import IsOnline from '../components/Grid/Cell/HostPage/IsOnline.vue'
import IsOnlineHeader from '../components/Grid/Cell/HostPage/IsOnlineHeader.vue'
import Ipv4 from '../components/Grid/Cell/HostPage/Ipv4.vue'
import Mac from '../components/Grid/Cell/HostPage/Mac.vue'
import Ipv6 from '../components/Grid/Cell/HostPage/Ipv6.vue'
import SwitchPortName from '../components/Grid/Cell/HostPage/SwitchPortName.vue'
import OSDetail from '../components/Grid/Cell/HostPage/OSDetail.vue'
import OSDetailHeader from '../components/Grid/Cell/HostPage/OSDetailHeader.vue'
import NetworkName from '../components/Grid/Cell/HostPage/NetworkName.vue'
import NetworkNameHeader from '../components/Grid/Cell/HostPage/NetworkNameHeader.vue'
import RegisterExtension from '../components/Grid/Cell/HostPage/RegisterExtension.vue'
import RegisterExtensionHeader from '../components/Grid/Cell/HostPage/RegisterExtensionHeader.vue'
import Status from '../components/Grid/Cell/HostPage/Status.vue'
import StatusHeader from '../components/Grid/Cell/HostPage/StatusHeader.vue'

// 主機列表中使用 Kendo Grid
const useGridInHost = () => {
  // grid 資料
  const dataItems = ref([])
  // 分頁用
  const pageable = {
    buttonCount: 1,
    pageSizes: [16, 32, 128, 255],
    type: 'input',
    info: false
  }
  const skip = ref(0)
  const take = ref(16)
  // 排序用
  const sort = ref([])
  // 選擇
  const selectedID = ref(0)
  const onRowClick = e => {
    console.log('onRowClick Id: ' + e.dataItem.Id)
    selectedID.value = e.dataItem.Id
  }
  // Header 欄位
  const columns = ref([
    {
      field: 'IsOnline',
      title: 'IP 狀態',
      width: '98px',
      locked: true,
      cell: markRaw(IsOnline),
      headerCell: markRaw(IsOnlineHeader)
    },
    {
      field: 'Ipv4',
      title: 'IP',
      width: '142px',
      locked: true,
      cell: markRaw(Ipv4)
    },
    {
      field: 'MAC',
      title: 'MAC',
      width: '150px',
      locked: true,
      cell: markRaw(Mac)
    },
    {
      field: 'Status',
      title: '主機狀態',
      width: '117px',
      cell: markRaw(Status),
      headerCell: markRaw(StatusHeader)
    },
    {
      field: 'Ipv6',
      title: 'IPv6',
      width: '235px',
      cell: markRaw(Ipv6)
    },
    {
      field: 'Duid',
      title: 'DUID',
      width: '336px'
    },
    {
      field: 'SwitchPortName',
      title: 'Port 名稱',
      width: '135px',
      cell: markRaw(SwitchPortName)
    },
    {
      field: 'Iaid',
      title: 'IAID',
      width: '99px'
    },
    {
      field: 'NetworkName',
      title: '所屬網段',
      width: '111px',
      cell: markRaw(NetworkName),
      headerCell: markRaw(NetworkNameHeader)
    },
    {
      field: 'OSDetail',
      title: '作業系統',
      width: '111px',
      cell: markRaw(OSDetail),
      headerCell: markRaw(OSDetailHeader)
    },
    {
      field: 'HotfixSyncTime',
      title: 'Hotfix 同步時間',
      width: '158px'
    },
    {
      field: 'RegisterExtension',
      title: '使用者分機',
      width: '96px',
      cell: markRaw(RegisterExtension),
      headerCell: markRaw(RegisterExtensionHeader)
    },
    {
      field: 'AntivirusVersion',
      title: '防毒版本',
      width: '103px'
    }
  ])
  // 手動分頁
  const result = computed(() => {
    const dataItemsWithPage = dataItems.value.slice(
      skip.value,
      take.value + skip.value
    )
    const dataItemsWithPageAndSort = orderBy(dataItemsWithPage, sort.value)
    return dataItemsWithPageAndSort.map(item => ({
      ...item,
      selected: item.Id === selectedID.value
    }))
  })
  // 總資料數
  const total = computed(() => {
    return dataItems.value ? dataItems.value.length : 0
  })

  // 生成假資料
  const createRandomData = count => {
    const onlineStatus = ['DHCP', 'Online', 'Offline', 'Static']
    const ipv4 = [
      ['192.168.187.111'],
      ['192.168.187.111'],
      ['192.168.187.111'],
      ['192.168.187.111', '192.168.137.11', '192.168.4.13']
    ]
    const mac = [
      { Address: '9C5C8EAFB438', StatusCode: 0 },
      { Address: '9C5C8EAFB438', StatusCode: 0 },
      { Address: '9C5C8EAFB438', StatusCode: 1 },
      { Address: '9C5C8EAFB438', StatusCode: 2 }
    ]
    const status = [
      [
        { IconCode: 3, Content: 'IP 封鎖(管理者)<br>172.168.2.3' },
        { IconCode: 1, Content: 'MAC 封鎖(管理者)' },
        { IconCode: 0, Content: '禁止變更 IP<br>192.168.87.254' }
      ],
      [
        { IconCode: 3, Content: 'IP 封鎖(管理者)<br>172.168.2.3' },
        { IconCode: 2, Content: '重要 IP<br>192.168.61.171' }
      ],
      [
        { IconCode: 3, Content: 'IP 封鎖(管理者)<br>172.168.2.3' },
        { IconCode: 4, Content: '未授權 MAC 封鎖' }
      ],
      [{ IconCode: 4, Content: '未授權 MAC 封鎖' }],
      [{ IconCode: 5, Content: '未符規白名單設備' }],
      []
    ]
    const ipv6 = [
      ['2001:b030:1228:1086::86:172'],
      ['2001:b030:1228:1086::86:172'],
      ['2001:b030:1228:1086::86:172'],
      [
        '2001:b030:1228:1086::86:172',
        '2001:b030:1228:1086::86:172',
        '2001:b030:1228:1086::86:172'
      ]
    ]
    const duid = [
      '',
      '00-01-00-01-1F-92-1A-7E-E0-B5-2D-21-30-07',
      '00-01-00-01-1F-92-1A-7E-E0-B5-2D-21-30-07'
    ]
    const switchPortName = [
      { ShortName: 'Fa0/21', FullName: '10021,FastEthernet0/21' },
      { ShortName: 'Fa0/21', FullName: '10021,FastEthernet0/21' },
      { ShortName: 'Port-channel1', FullName: '5001,Port-channel1' },
      { ShortName: 'Gi1/0/9', FullName: '10109,GigabitEthernet1/0/9' }
    ]
    const networkName = ['資訊室', '業務室']
    const iaid = ['', '184549306']
    const osDetail = [
      'Mac',
      'Windows',
      'Windows',
      'Pixis',
      'Unknown',
      'Unknown'
    ]

    return Array(count)
      .fill({})
      .map((_, idx) => ({
        Id: idx + 1, // Unique Id for select
        IsOnline: onlineStatus[Math.floor(Math.random() * onlineStatus.length)],
        Ipv4: ipv4[Math.floor(Math.random() * ipv4.length)],
        MAC: mac[Math.floor(Math.random() * mac.length)],
        Status: status[Math.floor(Math.random() * status.length)],
        Ipv6: ipv6[Math.floor(Math.random() * ipv6.length)],
        Duid: duid[Math.floor(Math.random() * duid.length)],
        SwitchPortName:
          switchPortName[Math.floor(Math.random() * switchPortName.length)],
        Iaid: iaid[Math.floor(Math.random() * iaid.length)],
        NetworkName:
          networkName[Math.floor(Math.random() * networkName.length)],
        OSDetail: osDetail[Math.floor(Math.random() * osDetail.length)],
        HotfixSyncTime: '2020/12/29 15:52:50',
        RegisterExtension: '111',
        AntivirusVersion: '12.0.1406'
      }))
  }

  const pageChangeHandler = e => {
    skip.value = e.page.skip
    take.value = e.page.take
  }

  // columns 重新排序
  const columnReorder = options => {
    columns.value = options.columns
  }

  // sort 處理
  const sortChangeHandler = e => {
    console.log('sort')
    sort.value = e.sort
  }

  return {
    columns,
    dataItems,
    pageable,
    skip,
    take,
    result,
    total,
    sort,
    createRandomData,
    pageChangeHandler,
    onRowClick,
    columnReorder,
    sortChangeHandler
  }
}

export default useGridInHost
